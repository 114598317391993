body {
  background-color: #101010 !important;
  position: relative;
}

.accordion-button {
  background-color: #202020 !important;
  color: #fff;
}

.accordion-body,
.card {
  background-color: #101010 !important;
  color: white;
}

.accordion-button {
  color: #00c0ff !important;
}
.accordion-button.collapsed {
  color: white !important;
}

.width-transition {
  transition: width 1.5s;
}

select {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
}

option {
  background-color: #202020 !important;
  color: white;
}

.sidebar {
  min-height: 100vh !important;
  background-color: #202020 !important;
  border-right: 1px solid black;
  position: fixed;
  color: #fff;
}

.sidebar-expanded {
  width: 10% !important;
}

.sidebar-collapsed {
  width: 3% !important;
}
